import React, { useState, useEffect } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { useStaticQuery } from "gatsby"

import { carouselItem, container, reset, wrapper } from './style.module.scss';


export default function({ style = { }, delay = 7000 }) {
  const { images } = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { relativePath: { glob: "carousel/*"} },
        sort: { order: ASC, fields: [name] }
      ) {
        nodes {
          name,
          childImageSharp {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  const [idx, setIdx] = useState(0);
  const [dir, setDir] = useState(1);

  useEffect(() => {
    const interval = window.setInterval(
      () => {
        if (idx + dir < images.nodes.length && idx + dir >= 0) {
          setIdx(idx + dir);
        } else if (dir === 1) {
          setDir(-1);
          setIdx(idx - 1);
        } else {
          setDir(1);
          setIdx(idx + 1);
        }
      }, delay);
    return () => window.clearInterval(interval);
  },[idx, dir]);

  return (
    <div className={wrapper} style={style}>
      <div 
        className={container}
        style={{ width: `${images.nodes.length * 100}%` }}
        >
        {images.nodes.map((img, i) => (
          <div className={reset} key={img.name} style={{ transform: `translate3d(${(i - idx) * -100}%, 0, 0)`}}>
            <BackgroundImage className={carouselItem} fluid={img.childImageSharp.fluid} />
          </div>
        ))}
      </div>
    </div>
  );
}