import React, { useRef } from "react"

import Layout from "../components/layout"
import { Container, Column } from "../components/twocol"
import SEO from "../components/seo"
import Carousel from '../components/carousel';
import TextBox, { body, contact, Kern, noWrap } from '../components/textbox';
import { Scrollable, ScrollTarget } from '../components/scrollTo';

import { textBox } from './index.module.scss';

const IndexPage = () => {
  const scrollStopEl = useRef(null);
  
  return (
    <Scrollable>
      <Layout scrollStopEl={scrollStopEl}>
        <SEO />
        <div ref={scrollStopEl}>
          <ScrollTarget>
            <Container style={{background: 'white', overflow: 'hidden'}}>
              <Column>
                <Carousel />
              </Column>
              <Column style={{position: 'relative'}}>
                <TextBox className={textBox}>
                  <div>
                    <p className={body}>
                      SI INSIGHT is an in-house creative agency and is a part of <a href="https://www.swissinstitute.net/">Swiss Institute</a>, a New York City independent non-profit space for international contemporary art. All SI INSIGHT projects directly support SI’s mission and benefit from the organization's curatorial expertise. 
                    </p>
                    <p className={body}>
                      SI INSIGHT provides cultural consultation and facilitates meaningful engagement with leading artists, curators, and museums globally.
                    </p>
                    <p className={body}>
                      SI INSIGHT’s past collaborations include Fortune Global 500 companies, and leading firms from the sectors of banking & finance, insurance, travel, and luxury.
                    </p>
                  </div>
                  <p className={contact}>
                    <span className={noWrap}>CONTACT US:</span>
                    <span className={noWrap}>
                      INF<Kern spacing={3}>O@</Kern>SI-INSIGHT<Kern spacing={1}>.</Kern>WORLD
                    </span>
                  </p>
                </TextBox>
              </Column>
            </Container>
          </ScrollTarget>
        </div>
      </Layout>
    </Scrollable>
  )
}

export default IndexPage
