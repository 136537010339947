import React from 'react';

import { container, column } from './style.module.scss';

export const Container = ({ children, ...props }) => (
  <div className={container} {...props}>
    {children}
  </div>
);

export const Column = ({ children, ...props }) => (
  <div className={column} {...props}>
    {children}
  </div>
);