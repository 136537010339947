import React from 'react';

import style from './style.module.scss';

export const body = style.body;
export const contact = style.contact;
export const noWrap = style.noWrap;

export const Kern = ({ children, spacing }) => (
  <span style={{ letterSpacing: spacing }}>
    {children}
  </span>
);

export default function({ children, className = '' }) {
  return (
    <div className={`${style.textbox} ${className}`}>
      {children}
    </div>
  );
}